import React from 'react'

import { Flex, Heading, OutlinedText, PlusIcon, RequestDemoForm, Text } from 'components'

const Contact = () => {
  return (
    <main style={{ background: '#111', height: 'auto' }}>
      <Flex
        w='100%'
        maxHeight={{ _: '100%', md: '900px' }}
        h={{ sm: '100vh', _: '100%' }}
        bg='mvrkDarkerGray'
        flexDirection={{ _: 'row' }}
        alignItems={{ sm: 'center', _: 'flex-start' }}
      >
        <Flex
          className='max-content-width'
          m='0 auto'
          pt={{ _: '100px', md: '50px' }}
          pb={{ _: '80px', md: '0px' }}
          flexDirection={{ _: 'column-reverse', md: 'row' }}
        >
          <Flex flexDirection={{ _: 'column' }} flex={1} order={{ _: 2, md: 1 }} pr={{ _: '', md: '40px' }}>
            <Heading color='primary' textTransform='uppercase' fontSize='14px' fontFamily='proxima' fontWeight='bold'>
              <Flex mb={{ _: '10px' }} mt={{ _: '10px', md: 0 }}>
                <PlusIcon mr={{ _: '4px', md: '10px' }} mt={{ _: '2px' }} />
                Vx360 3D Platform
              </Flex>
            </Heading>
            <Flex mb={{ _: '10px' }} flexDirection={{ _: 'column' }}>
              <OutlinedText
                textTransform='uppercase'
                lineHeight={{ _: '90%' }}
                fontSize={{ _: '36px', md: '64px', lg: '72px' }}
                fontFamily='nimbus'
                fontWeight='900'
                mr={{ md: '12px' }}
              >
                Ready?
              </OutlinedText>
              <Heading
                textTransform='uppercase'
                lineHeight={{ _: '36px', md: '64px', lg: '72px' }}
                fontSize={{ _: '36px', md: '64px', lg: '72px' }}
                fontFamily='nimbus'
                fontWeight='900'
              >
                Let's Talk!
              </Heading>
            </Flex>
            <Text color='mvrkLightGray' mb={{ _: '20px', md: '40px' }} fontSize={{ _: '14px', md: '16px' }}>
              Got a project you want to discuss?
              <br />
              Send us a message, we’d love to talk.
            </Text>
          </Flex>
          <Flex flex={1} pl={{ _: '', md: '20px' }} order={{ _: 1, md: 2 }} pt={{ _: '40px', md: '0px' }}>
            <RequestDemoForm isContactForm buttonVariant='primary' />
          </Flex>
        </Flex>
      </Flex>
    </main>
  )
}

export default Contact
